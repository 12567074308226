import React from 'react';
import { Link } from 'react-router-dom';
import './Mission.css';
import styled from 'styled-components';

// Styled Components for Press Coverage
const PressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  color: white;
  padding: 4rem 2rem;
  font-family: 'Arial', sans-serif;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  color: white;
  margin-bottom: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const LogoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 30px;
  justify-items: center;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
`;

const LogoLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const LogoImage = styled.img`
  width: 100%;
  height: 110px; /* Fixed height for consistency */
  object-fit: contain; /* Ensures the image scales uniformly */
  background: white;
  border-radius: 5px;
  padding: 15px;

  @media (max-width: 768px) {
    height: 120px;
    padding: 10px;
  }

  @media (max-width: 576px) {
    height: 100px;
    padding: 8px;
  }
`;


function Mission() {
  return (
    <>
      {/* Press Coverage Section Added Here */}
      <PressContainer>
        <Heading>Press Coverage</Heading>
        <LogoGrid>
          <LogoLink href="/n14">
            <LogoImage src="/images/NYTimes.png" alt="NYTimes" />
          </LogoLink>
          <LogoLink href="/n13">
            <LogoImage src="/images/NYTimes.png" alt="NYTimes" />
          </LogoLink>
          <LogoLink href="/n20">
            <LogoImage src="/images/Wired_logo.svg.png" alt="UN" />
          </LogoLink>
          <LogoLink href="/n9">
            <LogoImage src="/images/CBS.png" alt="CBS" />
          </LogoLink>
          <LogoLink href="/n8">
            <LogoImage src="/images/CBS.png" alt="CBS" />
          </LogoLink>
          <LogoLink href="/n10">
            <LogoImage src="/images/peoplelogo.png" alt="People" />
          </LogoLink>
          <LogoLink href="/n15">
            <LogoImage src="/images/sbslogo.png" alt="SBS" />
          </LogoLink>
          <LogoLink href="/n11">
            <LogoImage src="/images/The-Guardian-logo.png" alt="The Guardian" />
          </LogoLink>
          <LogoLink href="/n1">
            <LogoImage src="/images/southchinamorningpost.png" alt="SCMP" />
          </LogoLink>
          <LogoLink href="/n12">
            <LogoImage src="/images/logo-the-street.png" alt="The Street" />
          </LogoLink>
          <LogoLink href="/n15">
            <LogoImage src="/images/scripps_news_logo.png" alt="Scripps" />
          </LogoLink>
          <LogoLink href="/n17">
            <LogoImage src="/images/techcrunch-logo.png" alt="TechCrunch" />
          </LogoLink>
          <LogoLink href="/n19">
            <LogoImage src="/images/Logo_of_the_United_Nations.jpg" alt="UN" />
          </LogoLink>
        </LogoGrid>
      </PressContainer>

      {/* Original Mission Content Below */}
      <div className="description-container">
        <div className="description-left">
          <div className="image-container">
            {/* <img src="https://i.imgur.com/leCvK7i.png" alt="sun" className="featured-img" /> */}
            <img src="https://i.imgur.com/9jrZrhH.png" alt="Alecto AI Logo" />
          </div>
        </div>
        <div className="description-right">
          <h1>Our Solution</h1>
          <p>
            - Designed to be compassionate and easy to use, prioritizing a human-centered approach over an institutional-focused one. 
            <br/>
            - Verifies consent directly through the individual
            <br/>
            - Searches and securely verifies countless reports, addressing the buildup of both crucial and false reports.
            <br/>
            - Addresses both systemic and systematic issues on large public platforms.
          </p>
          <Link to="/WhyPage" className="yellow-button">
            Learn more
          </Link>
        </div>
      </div>
    </>
  );
}

export default Mission;
