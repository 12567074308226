import React from 'react';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Solution from '../Solution';
import Press from '../Press';
import Footer from '../Footer';
import StoryCards from '../StoryCards';
import Description from '../Description'; 
import NewsletterSignup from '../NewsletterSignup';
import Mission from '../Mission';

import '../../App.css';

function Home() {
  return (
    <>
      <div style={{ backgroundColor: 'black' }}>
        <HeroSection />
        <Description /> {/* Large Image remains here */}
        <Solution />
        <Mission /> {/* Now contains the Press Coverage section */}
        <Cards />
        <Press />
        <NewsletterSignup />
        <Footer />
      </div>
    </>
  );
}

export default Home;
