import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  color: white;
  padding: 3rem 2rem;
  font-family: 'Arial', sans-serif;
  margin-top: 0; /* Remove extra top margin */
`;

const LargeImage = styled.img`
  width: 95%;
  max-width: 1200px;
  height: auto;
  max-height: 650px;
  object-fit: contain;
  margin-bottom: 2rem;
  display: block;
`;

const AlectoAIDescription = () => {
  return (
    <Container>
      <LargeImage src="/images/deck_image_2.png" alt="Alecto AI Banner" />
      <LargeImage src="/images/deck_image_1.png" alt="Alecto AI Banner" />
    </Container>
  );
};

export default AlectoAIDescription;
