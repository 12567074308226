import React from 'react';

function ThreeBoxes() {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px', // Small spaces between boxes
    flexWrap: 'wrap', // Wrap boxes to the next line on smaller screens
    color: '#ffff',
  };

  const boxStyle = {
    flex: '1 1 300px', // Let each box have a minimum width of 300px
    maxWidth: '300px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    margin: '10px', // Add margin to space out the boxes
    position: 'relative', // Set relative positioning for the box
    backgroundColor: '#3C3C3C', /* Dark grey background color */
  };

  const boxContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const headerContainerStyle = {
    textAlign: 'center',
    marginBottom: '20px',
    padding: '2rem 1rem', // Reduced padding for left and right
  };

  const headerStyle = {
    fontWeight: 'bold',
    fontSize: '35px',
    color: 'white',
  };

  const paragraphStyle = {
    fontSize: '18px',
    color: '#ffff',
    marginLeft: '10%', // Add left and right margin
    marginRight: '10%', // Add left and right margin
  };

  const h3 = {
    color: 'blue',
  }

  // Media query for smaller screens
  const smallerScreenMediaQuery = '@media (max-width: 768px)';
  const smallerScreenStyles = {
    headerContainerStyle: {
      padding: '2rem 0.5rem', // Even smaller padding for very small screens
    },
    boxStyle: {
      flex: '1 1 100%',
      maxWidth: '100%',
    }
  };

  // Apply responsive styles using the style object spread notation
  const responsiveHeaderContainerStyle = {
    ...headerContainerStyle,
    ...(window.innerWidth <= 768 && smallerScreenStyles.headerContainerStyle),
  };

  return (
    <div>
      <div style={responsiveHeaderContainerStyle}>
        {/* <h2 style={headerStyle}>Our Mission</h2> */}
        <p style={paragraphStyle}>
          The system was so broken, the only way to get justice was to go around it.
        </p>
      </div>

      <div style={containerStyle}>
        <div style={boxStyle}>
          <img src="images/mission_image_1.png" alt="Facial Recognition" style={{ width: '100%', borderRadius: '8px' }} />
          {/* <div style={boxContentStyle}>
            <h3 style={{ color: 'white' }}>Facial Recognition Tools</h3>
            <p>
              - Prioritizes institutions over individuals
              <br />
              - Costly implementation
              <br />
              - Restricts public accessibility
              <br />
              - Raises concerns about AI ethics
            </p>
          </div> */}
        </div>

        <div style={boxStyle}>
          <img src="images/mission_image_2.png" alt="Enterprise" style={{ width: '100%', borderRadius: '8px' }} />
          {/* <div style={boxContentStyle}>
            <h3 style={{ color: 'white' }}>Enterprise</h3>
            <p>
              Traditional content moderation and reporting may result in... 
              <br />
              - Legal risks
              <br />
              - Buildup of false reports
              <br />
              - Large costs for platforms
              <br />
              - Struggle to comply with shifting regulations
            </p>
          </div> */}
        </div>

        <div style={boxStyle}>
          <img src="images/mission_image_3.png" alt="Individuals" style={{ width: '100%', borderRadius: '8px' }} />
          {/* <div style={boxContentStyle}>
            <h3 style={{ color: 'white' }}>Individuals</h3>
            <p>
              - Directly victimized by facial information abuse
              <br />
              - Have no access to methods that prevent or identify abuse
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ThreeBoxes;
