import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: white;
  padding: 3rem 1rem; /* Adjusted padding */
  font-family: 'Arial', sans-serif;
  flex-wrap: wrap;
  margin-bottom: 0; /* Ensures no extra space below */

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 5rem;
  }

  @media (min-width: 1200px) {
    padding: 6rem;
  }
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 0; /* Fix spacing issue */

  @media (max-width: 767px) {
    order: 3;
  }

  @media (min-width: 768px) {
    width: 50%;
    order: 1;
  }
`;

const BiggerHeading = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;

  @media (max-width: 767px) {
    margin-bottom: 2rem;
  }
  
  @media only screen and (max-width: 600px) {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }

  @media (min-width: 992px) {
    font-size: 5rem;
  }
`;

const Description = styled.p`
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 300;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 2;

  @media (max-width: 767px) {
    order: 2;
  }
  @media (min-width: 768px) {
    order: 3;
  }
`;

const PhoneImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 400px;

  @media (min-width: 768px) {
    max-width: 500px;
  }

  @media (min-width: 576px) {
    max-width: 300px;
  }
`;

const ButtonImages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const AppButton = styled(Link)`
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 30px;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-family: 'Arial', sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 5px 10px;

  &:hover {
    background-color: #333;
    transform: translateY(-2px);
  }
`;

const Icon = styled.i`
  margin-right: 0.5rem;
`;

function HeroSection() {
  return (
    <HeroContainer>
      <ContentSection>
        <BiggerHeading>Alecto AI</BiggerHeading>
        <Description>Online Identity Infrastructure</Description>

        <ButtonImages>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px', flexWrap: 'wrap' }}>
            <AppButton as="a" href="https://apps.apple.com/us/app/alecto-ai/id1577004341" target="_blank" rel="noopener noreferrer">
              <Icon className="fa-brands fa-apple" /> Download 
            </AppButton>
            <AppButton as="a" href="https://play.google.com/store/apps/details?id=com.alectoreactandroidapp" target="_blank" rel="noopener noreferrer">
              <Icon className="fa-brands fa-android" /> Download 
            </AppButton>
          </div>

          <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'center', width: '100%' }}>
            <AppButton as="a" href="https://www.alectoai.org/" target="_blank" rel="noopener noreferrer">
              <Icon className="fa-solid fa-heart" /> Make a Donation to Alecto
            </AppButton>
          </div>
        </ButtonImages>
      </ContentSection>

      <RightSection>
        <PhoneImage src="/images/iphonescreen-4.png" alt="Phone" />
      </RightSection>
    </HeroContainer>
  );
}

export default HeroSection;
