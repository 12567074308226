import React, { useState } from 'react';
import styled from 'styled-components';

const TopImageWrapper = styled.div`
  width: 100%;
  padding: 20px 0;
  background-color: black;

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 1400px; /* limit how wide it can go on big screens */
    max-height: 800px;  /* increased from 500px */
    object-fit: contain;

    @media (max-width: 768px) {
      max-height: 400px; /* increased from 300px */
    }
  }
`;


const MissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  padding: 20px;
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
`;

const StyledImage = styled.img`
  width: calc(50% - 10px);
  max-height: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 10px 0;
  }
`;

const StyledImage1 = styled.img`
  width: calc(90% - 10px);
  max-height: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 10px 0;
  }
`;

const StyledImage2 = styled(StyledImage1)``;

const Heading = styled.h2`
  color: #fff;
  margin-bottom: 0.5em;
  text-align: center;
`;

const Paragraph = styled.p`
  line-height: 1.6;
  color: #ccc;
  text-align: left;
`;

const AboutMission = () => {
  const [imageHeight, setImageHeight] = useState('auto');

  const handleImageLoad = (event) => {
    const newHeight = event.target.offsetHeight / 2;
    setImageHeight(`${newHeight}px`);
  };

  return (
    <>
      <TopImageWrapper>
        <img
          src="images/team_profile_1_v3.1.png"
          alt="Empowering digital safety"
        />
      </TopImageWrapper>
      <TopImageWrapper>
        <img
          src="images/team_profile_2_v3.8.png"
          alt="Empowering digital safety"
        />
      </TopImageWrapper>
      <MissionContainer>
        <ContentContainer>
          <TextContainer>
            <h1>Our Mission</h1>
            <Paragraph>
              The system was so broken, the only way to get justice was to go around it.
            </Paragraph>
            <Paragraph>
              Alecto, founded by a revenge porn survivor and women of color,
              fights image-based abuse and racial bias with advanced,
              compassionate technology. Our biometrically secure, anti-racist
              facial recognition tool empowers individuals to swiftly search for
              harmful images of themselves online.
            </Paragraph>
            <Paragraph>
              Alecto isn't just a product; it's a movement against digital abuse
              and biased algorithms. We envision a future free from algorithmic
              injustice and digital abuse.
            </Paragraph>
          </TextContainer>
          <ImageContainer>
            <StyledImage
              src="https://images.pexels.com/photos/7480125/pexels-photo-7480125.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="Alecto's mission against digital abuse"
              onLoad={handleImageLoad}
            />
            <div>
              <StyledImage1
                src="https://images.pexels.com/photos/5871420/pexels-photo-5871420.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Alecto's advanced facial recognition tool"
                style={{ height: imageHeight }}
              />
              <StyledImage2
                src="https://images.pexels.com/photos/5871420/pexels-photo-5871420.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Alecto's advanced facial recognition tool"
                style={{ height: imageHeight }}
              />
            </div>
          </ImageContainer>
        </ContentContainer>
      </MissionContainer>
    </>
  );
};

export default AboutMission;
